import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import ReactDOM from 'react-dom'
import _, { get, isEmpty } from 'lodash'
import $ from 'jquery'

// UTILS
import * as UtilsCommon from 'utils/new_booking/common'
import locationUtils from 'utils/common/location'
import { Utils } from 'utils/Utils'
import roundTripDiscountUtils from 'utils/common/booking/roundTripDiscount'
import { ShoppingUtils } from 'utils/booking/ShoppingUtils'
import { CPODUtils } from 'utils/booking/CPODUtils'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// API
import LocationAPI from 'api/locations'
import BookingAPI from 'api/bookings'
import PaymentIntegrationAPI from 'api/payment-integration'
// COMPONENTS
import DynamicPopupWithButton from '../../common/DynamicPopupWithButton'
import InvalidPaymentPopup from 'components/booking_detail/modal/InvalidPaymentModal'

// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as discountCodeActionCreators from 'store/actions/new_booking/discountCodeActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as roundTripDiscountActionCreators from 'store/actions/new_booking/roundTripDiscountActionCreators'
import * as calendarBookingActionCreators from 'store/actions/common/calendarActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'
import * as currentPopupIDActionCreators from 'store/actions/common/currentPopupIDActionCreators'
import * as firebaseTrackingActionCreators from 'store/actions/tracking_booking/firebaseTrackingActionCreators'
// CONSTANTS
import {
  ID_RECIPIENT_NAME,
  ID_RECIPIENT_PHONE,
} from 'constants/newBookingConstants'
import {
  POPUP_ID_FULL_DAY_S1,
  POPUP_ID_OUT_OF_SERVICE,
  POPUP_ID_LONG_HAUL_PICKUP,
  SHOW_DATE_PICKER_BY_BOOKING,
  POPUP_SUBMIT_EDITS,
  INVALID_PAYMENT_POPUP,
} from 'constants/common/popupConstants'
import { ICON_CEB_REQUEST_CHANGES, ICON_QUOTE_ID } from 'constants/imageConstants'
import {
  NOW,
  SCHEDULE,
  FULL_DAY,
  QUICK_CHOICE,
  LONG_HAUL,
  NEW_GEN_POD,
  PRICING_PAYMENT_INCREASE,
} from 'constants/bookingConstants'
import ServiceType from '../service_type/ServiceType'
import { isPaymentBooking, setDefaultMaxLocation } from 'utils/booking/common'
import SubmitEditsModal from '../../booking_detail/modal/SubmitEdits'
import { withNavigate } from 'hocs/withNavigate'
import { checkLocationsActionsCreator } from 'store/toolkit/newBooking/checkLocations.reducer'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { outOfServiceStatusActionsCreator } from 'store/toolkit/outOfServiceStatus/outOfServiceStatus.reducer'
import { autoSwitchLongHaulActionsCreator } from 'store/toolkit/newBooking/autoSwitchLongHaul.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { popupCodActionsCreator } from 'store/toolkit/popupCod/popupCod.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer'
import mapUtils from 'utils/common/map'
import { SIGN_IN_MODAL, SIGN_UP_MODAL } from '../guest_flow/constants'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { appsFlyerTrackEvent, generateAppsFlyerEventObj } from 'utils/trackingAppsFlyer'
import { BOOKING1_NEXT_PRESS, BOOKING2_SERVICE_PRESS, BOOKING3_CONFIRM_PRESS, BOOKING3_CONFIRM_PRESS_BEFORE_SIGNED_IN, BOOKING3_CONFIRM_PRESS_FAILED } from 'constants/trackingAppsFlyer'
import CommonUtils from 'utils/common'
import TallyUtils from 'utils/booking/TallyUtils'
// import {FULL_DAY} from 'constants/bookingConstants';
// ASSETS

const mapStateToProps = state => ({
  serviceTypes: state.serviceTypes,
  currentVehicleType: UtilsCommon.currentVehicleType(state),
  currentServiceType: UtilsCommon.currentServiceType(state),
  currentCustomer: state.currentCustomer,
  currentStep: state.currentStep,
  discountCode: state.discountCode,
  extraInfos: state.extraInfos,
  pickupTime: state.pickupTime,
  extraServices: state.extraServices,
  locations: state.locations,
  timeType: state.timeType,
  timeTypeUI: state.timeTypeUI,
  selectedServiceTypeID: state.selectedServiceTypeID,
  outOfServiceStatus: state.outOfServiceStatus,
  quickChoiceID: state.quickChoiceID,
  others: state.others,
  tmpLocations: state.tmpLocations,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  documentReturn: state.documentReturn,
  bookAgainDetails: state.bookAgainDetails,
  checkLocations: state.checkLocations,
  assignedPickupTime: state.assignedPickupTime,
  booking: state.booking,
  dataChange: state.dataChange,
  roundTripDiscount: state.roundTripDiscount,
  shouldGoToStep2: state.stepNewLongHaul.shouldGoToStep2,
  popupId: state.currentPopupID,
  assignedDriver: state.assignedDriver,
})

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    discountCodeActions: bindActionCreators(discountCodeActionCreators, dispatch),
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    documentReturnActions: bindActionCreators(documentReturnActionsCreator, dispatch),
    outOfServiceStatusActions: bindActionCreators(outOfServiceStatusActionsCreator, dispatch),
    popupCodActions: bindActionCreators(popupCodActionsCreator, dispatch),
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    pickupTimeActions: bindActionCreators(pickupTimeActionsCreator, dispatch),
    autoSwitchLongHaulActions: bindActionCreators(autoSwitchLongHaulActionsCreator, dispatch),
    checkLocationActions: bindActionCreators(checkLocationsActionsCreator, dispatch),
    datePickerActions: bindActionCreators(showDatePickerActionsCreator, dispatch),
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
    timeTypeUIActions: bindActionCreators(timeTypeUIActionsCreator, dispatch),
    roundTripDiscountActions: bindActionCreators(roundTripDiscountActionCreators, dispatch),
    calendarBookingActions: bindActionCreators(calendarBookingActionCreators, dispatch),
    customerActions: bindActionCreators(customerActionCreators, dispatch),
    popupActions: bindActionCreators(currentPopupIDActionCreators, dispatch),
    firebaseTrackingActions: bindActionCreators(firebaseTrackingActionCreators, dispatch),
    othersActions: bindActionCreators(othersActionsCreator, dispatch),
    accountManageActionsProps: bindActionCreators(accountManageActions, dispatch)
    
  }
}

class StepButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      preCurrentCustomer: {
        current_company_id: '',
        authentication_token: '',
      },
      isShowSignUpModal: false
    }
    this.root = { // root save origin pickup time and timeType
      timeType: props.timeType,
      pickupTime: props.pickupTime
    }

    this.firebasePayment = React.createRef(null)
  }

  componentDidMount() {
    const {
      bookAgainDetails, documentReturnActions, onRef, currentCustomer,
    } = this.props
    onRef(this)
    if (!_.isEmpty(bookAgainDetails && bookAgainDetails.booking_tracking)) {
      documentReturnActions.updateDocumentReturn(bookAgainDetails.booking_tracking)
    }
    if (!_.isEmpty(currentCustomer) && currentCustomer.authentication_token) {
      this.setState({
        preCurrentCustomer: currentCustomer,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { preCurrentCustomer } = this.state
    const { currentCustomer, currentStep, customerActions } = nextProps
    if (!_.isEmpty(preCurrentCustomer) && !_.isEmpty(currentCustomer)) {
      const { current_company_id: currentCompanyId, authentication_token: preAuthenticationToken } = preCurrentCustomer
      const { current_company_id: nextCurrentCompanyId, authentication_token: authenticationToken } = currentCustomer
      if (_.toString(currentCompanyId) !== _.toString(nextCurrentCompanyId)
        && currentStep === 3 && authenticationToken) {
        this.setState({
          preCurrentCustomer: currentCustomer,
        })
        if (_.isEmpty(preAuthenticationToken) && authenticationToken) {
          customerActions.handleSetIsLoginStep3(true)
        }
        if (preAuthenticationToken && authenticationToken) {
          customerActions.handleSetIsLoginStep3(true)
        }
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  shouldContinueToCreateBooking() {
    const {
      currentStep,
      pickupTime,
      timeType,
      currentVehicleType,
      datePickerActions,
      calendarBookingActions,
      fullLoadView,
      serviceTypes,
      selectedServiceTypeID,
      stepActions,
      setIconComingSoonService,
      setShowComingSoonService,
      bookAgainDetails,
      booking
    } = this.props
    const { timeType: originTimeType, pickupTime: originPickupTime } = this.root || {}
    const isQuickChoice = Utils.quickChoiceSelected()
    // check available servicetype
    const selectedServiceType = _.find(serviceTypes, serviceType => serviceType.id === selectedServiceTypeID)
    if (selectedServiceType?.is_coming && fullLoadView) {
      setShowComingSoonService(true)
      setIconComingSoonService(selectedServiceType.inactive_icon_url)
      ServiceType.checkHeightPopup()
      stepActions.loaded()
      return false
    }
    // end
    // check time type, pickup time change when edit booking
    if ((_.isEmpty(pickupTime)
      || _.isEmpty(timeType)
      || (moment(pickupTime).format('YYYY-MM-DD HH:mm Z') === moment(originPickupTime).format('YYYY-MM-DD HH:mm Z')
        && timeType === originTimeType)) && UtilsCommon.isCustomerEditBooking()) {
      return true
    }
    // end
    if (!isQuickChoice && currentStep !== 2) {
      const minimumPickupTime = UtilsCommon.miniMumMinutes(currentVehicleType, timeType, 0)
      const pickupTimeToMinutes = Utils.convertPickupTimeToMinutes(pickupTime || 0)
      if (pickupTimeToMinutes < minimumPickupTime && fullLoadView) {
        if (UtilsCommon.isCustomerEditBooking() && (timeType === bookAgainDetails?.time_type) && !!booking?.driver) {
          return true
        }
        datePickerActions.showDatePicker(true)
        calendarBookingActions.add(`${SHOW_DATE_PICKER_BY_BOOKING}-SINGLE`)
        return false
      }
    }
    return true
  }

  stepChecking() {
    const {
      currentStep,
      fullLoadView,
      isErrorPickupLocation,
      handleShowChangePickupLocation,
    } = this.props
    if (isErrorPickupLocation) {
      handleShowChangePickupLocation(true)
      return
    }
    const btnClose = document.getElementsByClassName('Popover custom visible')
    if (btnClose && btnClose[0]) {
      const btnClassName = btnClose[0].className
      btnClose[0].className = _.replace(btnClassName, ' visible', '')
    }

    if(currentStep === 3) {
      this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS)
    }

    if (this.shouldContinueToCreateBooking()) {
      switch (currentStep) {
        case 1:
          if (fullLoadView) {
            this.checkAutoSwitchLongHaul()
          } else {
            this.checkGotoLTLBooking()
          }
          break
        case 2:
          this.checkInitTmpLocationsDocumentReturn()
          break
        case 3:
          this.checkToSubmit()
          break
        default:
          break
      }
    }
  }

  verifyShoppingList() {
    const {
      myShopping,
      isShopping,
    } = this.props
    if (isShopping && myShopping) {
      if (myShopping.verifyShoppingListItems()) {
        return true
      }
      if (myShopping.verifyBudgetCustomer()) {
        return true
      }
    }
    return false
  }

  verifyShoppingActions() {
    const {
      extraInfos,
      currentCustomer,
      myShopping,
      isShopping,
      timeType,
      booking,
      dataChange,
      accountManageActionsProps
    } = this.props

    if (myShopping) {
      if (myShopping.hasBlockBooking()) {
        myShopping.showPopupBlockBooking()
        return true
      }

      if (isShopping) {
        if (myShopping.verifyOpeningTime()) {
          return true
        }

        if (UtilsCommon.isCustomerEditBooking()) {
          return true
        }

        // prevent user process with timetype different NOW
        if (timeType !== NOW) {
          return true
        }

        if (currentCustomer.id === 0) {
          accountManageActionsProps.updateModalAccountManage(SIGN_IN_MODAL)
          return true
        }

        const isBusiness = !!(currentCustomer && _.toInteger(currentCustomer.current_company_id) > 0)
        if (isBusiness) {
          accountManageActionsProps.updateModalAccountManage(SIGN_UP_MODAL)
          return true
        }

        // Validate cerdit balance with minimumCreditBalance of vehicle type
        if (myShopping.verifyCreditBalance(currentCustomer)) {
          myShopping.requestApiGetCreditBalance(['credit_balance', 'has_upcoming_shopping_booking'], (response) => {
            if (myShopping.verifyCreditBalance(response)) {
              myShopping.showPopupShoppingWallet()
              return true
            }
            return this.stepChecking()
          })
          return true
        }

        // Validate cerdit balance with booking subtotal
        if (ShoppingUtils.verifyCreditBalance(booking.subtotal, currentCustomer) && !dataChange) {
          ShoppingUtils.showPopupShoppingWallet(
            booking.subtotal,
            currentCustomer,
            extraInfos,
            document.getElementById('DefaultPopup')
          )
          return true
        }
      }
    }
    return false
  }

  verifyLocationsCPod() {
    const { locations, checkLocations } = this.props
    let hasInvalidCPOD = false
    if (checkLocations && checkLocations.cod_pod === 'cpod') {
      _.forEach(locations, (location) => {
        if (location.need_cod && !location.need_pod) hasInvalidCPOD = true
      })
    }

    if (hasInvalidCPOD) {
      toastr.error(I18n.t('errors.booking.wrong_cpod'))
      return false
    }
    return true
  }

  verifyCustomReimbursement() {
    const { extraServices } = this.props
    // U2.1 Check amount < customer_maximum_cap_per_location for input
    const customReimbursements = _.get(extraServices, 'customReimbursements', null)
    const customReimbursementsError = customReimbursements.some(item => _.get(item, 'invalid', false) === true)
    if (customReimbursementsError) return false
    return true
  }

  handleCheckValidBeforeCallAPI() {
    const {
      locations, timeType, roundTripDiscount,outsideList, errorDistanceList,
      extendedList, setIsShowChangeTimeType,
    } = this.props

    // verify if error is showing
    const inputError = $('.Locations.Locations-Customs').find('.block-item .Input.error').length
    if (inputError) {
      return false
    }

    // verify Empty Value on Step 1
    const invalidIndexes = locationUtils.invalidLocationIndexes(
      timeType, locations, roundTripDiscount
    )

    const verifyEmpty = UtilsCommon.verifyEmptyValueStep01(timeType, invalidIndexes)
    if (verifyEmpty) {
      return false
    }

    // validate lazy address
    let validLazy = true
    const filledLocationIndexes = _.filter(
      locations,
      location => location.lat !== undefined || location.lng !== undefined
    )

    _.each(filledLocationIndexes, (location) => {
      const isLazyAddress = location.lazyAddress && !!location.lazyAddress?.[location.id]
      if (isLazyAddress) {
        validLazy = false
      }
    })

    if (!validLazy || !isEmpty(outsideList) || !isEmpty(errorDistanceList)) {
      document.querySelector('.dlvr-error-input-border').scrollIntoView()
    }

    if (!isEmpty(extendedList)) {
      setIsShowChangeTimeType(true)
      return false
    }

    if (!validLazy || !isEmpty(outsideList) || !isEmpty(errorDistanceList)) {
      return false
    }

    if (this.verifyShoppingActions()) {
      return false
    }
    return true
  }

  checkAutoSwitchLongHaul() {
    const {
      stepActions, locations, checkLocationActions,
      currentVehicleType, selectedServiceTypeID,
      selectedVehicleTypeID, currentCustomer, assignDriverActions,
      timeType, timeTypeUI, locationActions, extraInfos, pickupTime
    } = this.props
    if (this.handleCheckValidBeforeCallAPI()) {
      stepActions.loading()
      this.getFavoriteAmount(() => {
        if (currentVehicleType?.settings?.long_haul_enabled
          && (timeType !== LONG_HAUL || (timeTypeUI && timeTypeUI !== LONG_HAUL))) {
          let timeTypeTemp = timeType
          if (_.isEmpty(timeType)) {
            timeTypeTemp = LONG_HAUL
          }
          const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
          LocationAPI.getCheckLocations(
            locations,
            { timeType: timeTypeTemp, serviceTypeID: selectedServiceTypeID, vehicleTypeID: selectedVehicleTypeID, pickupTime },
            {
              countryCode: currentCustomer.country_code || extraInfos?.country_code,
              companyId: currentCustomer.current_company_id,
              isEnableGoogleMap
            },
            null,
            (result) => {
              if (result.status === 400) {
                toastr.error(result.error)
                stepActions.loaded()
                return true
              }
              Promise.resolve(
                checkLocationActions.updateCheckLocationInfos(result)
              ).then(() => {
                const { documentReturn: cloneObj } = this.props
                locationActions.updateCODPODFees(cloneObj, result)
              })
              if (result?.estimated_working_time) assignDriverActions.updateEstimatedWorkingTime(result.estimated_working_time)
              if (result.long_haul_address_valid && result.long_haul_pickup.is_valid) {
                $('.PickupTime').removeClass('error')
                this.autoSwitchToLongHaul(result)
              } else {
                if (_.isEmpty(timeType)) {
                  stepActions.loaded()
                  return true
                }
                this.checkToGoToStep2()
              }
    
              return false
            }
          )
        } else {
          this.checkToGoToStep2(true)
        }
        return true
      })
    }
  }

  autoSwitchToLongHaul(result) {
    const {
      stepActions, timeTypeActions, extraInfos, roundTripDiscountActions, roundTripDiscount, autoSwitchLongHaulActions, datePickerActions, timeTypeUIActions
    } = this.props
    const isTimeLongHaulValid = this.isTimeLongHaulValid()
    autoSwitchLongHaulActions.autoSwitchLongHaul(true)
    if (roundTripDiscount) {
      roundTripDiscountActions.updateRoundTripValue(false)
    }
    datePickerActions.showDatePicker(!isTimeLongHaulValid)
    if (isTimeLongHaulValid === true) {
      // Switched Long Haul then go step 2
      timeTypeActions.actionChangeTimeType(LONG_HAUL)
      if (extraInfos.show_long_haul_time_type) {
        timeTypeUIActions.changeTimeTypeUI(LONG_HAUL)
      }
      this.handleResultApi(result, true)
      autoSwitchLongHaulActions.autoSwitchLongHaul(false)
    } else {
      // Switched Long Haul and stay in step 1
      stepActions.loaded()
    }
  }

  isTimeLongHaulValid() {
    const {
      currentVehicleType, pickupTime,
      quickChoiceID, timeType,
      pickupTimeActions
    } = this.props
    let timePickup = pickupTime
    const minutes = UtilsCommon.miniMumMinutes(currentVehicleType, LONG_HAUL)
    const timeLongHaul = moment().add(minutes, 'minutes').set({ second: 0 }).format()
    if (quickChoiceID > 0 && timeType === SCHEDULE) {
      const quickChoiceType = _.find(currentVehicleType.quick_choices, { id: quickChoiceID })
      const minutesQuickChoice = quickChoiceType.schedule_time
      timePickup = moment().add(minutesQuickChoice, 'minutes').set({ second: 0 })
      pickupTimeActions.changePickupTime(timePickup.format())
    } else {
      timePickup = moment(pickupTime).set({ second: 0 })
    }
    return timePickup.isValid() && timePickup.isSameOrAfter(timeLongHaul)
  }


  // eslint-disable-next-line class-methods-use-this
  checkTypeAddress(types = []) {
    let isTypeCountry = false
    types.forEach((item) => {
      if (item === 'country') {
        isTypeCountry = true
      }
    })
    return isTypeCountry
  }


  checkIsOutOfServiceLTL() {
    let isError = false
    const { locations, extraInfos, bookingActions } = this.props
    locations.forEach((item) => {
      const addressComponent = get(item, 'address_components') || []
      if (addressComponent.length > 0) {
        addressComponent.forEach((address) => {
          if (this.checkTypeAddress(address.types) === true && address.short_name !== extraInfos.country_code) {
            isError = true
          }
        })
      }
    })
    bookingActions.updateOutOfServicePartialLoad(isError)
    return isError
  }

  checkGotoLTLBooking() {
    const {
      locations, submitPartial
    } = this.props
    const isOutService = this.checkIsOutOfServiceLTL()
    const invalidIndexes = locationUtils.invalidLocationIndexes(
      null, locations
    )
    const locationElems = $('.Locations.Locations-Customs').find('.block-item .Input')
    invalidIndexes.forEach((index) => {
      $(locationElems[index]).addClass('error')
    })
    if (invalidIndexes.length === 0 && !isOutService) {
      submitPartial()
    }
  }

  getFavoriteAmount(cb) {
    const {
      currentCustomer,
      selectedVehicleTypeID,
      bookingActions,
      extraInfos,
    } = this.props

    if (currentCustomer.authentication_token) {
      const params = {
        authentication_token: currentCustomer.authentication_token,
        include_fleet_driver: true,
        vehicle_type_id: selectedVehicleTypeID,
        area_id: currentCustomer.area_id,
        country_code: extraInfos.country_code,
        company_id: currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id,
      }

      bookingActions.getFavoriteAmount(params, cb)
    } else {
      cb()
    }
  }

  prevStep(step) {
    const {
      currentStep, currentCustomer,
      stepActions, popupCodActions,
      checkLocationActions,
      discountCodeActions,
      bookingActions,
      customerActions,
      locations,
      timeType,
      selectedVehicleTypeID,
      popupActions,
    } = this.props
    const data = {
      locations_attributes: locations,
      time_type: timeType,
      vehicle_type_id: selectedVehicleTypeID
    }
    const paramStep = step || null
    if (currentStep === 3) {
      popupActions.updateCurrentPopupID('')
    }
    if (currentStep === 3 && currentCustomer.current_company_id === 0) {
      stepActions.loading()
      BookingAPI.getCODPODPopup(currentCustomer.authentication_token, currentCustomer.area_id, (result) => {
        const popupCodPod = { cod_popup_content: result.data }
        Promise.resolve(
          popupCodActions.popupCOD(popupCodPod)
        ).then(() => {
          stepActions.prevStep(paramStep)
        }).then(() => {
          stepActions.loaded()
        })
      })
    } else {
      if (currentStep === 2) {
        checkLocationActions.updateCheckLocationInfos(null)
        customerActions.calculateCustomerCashbackPercent(data)
      }
      stepActions.prevStep(paramStep)
    }
    return discountCodeActions.markDiscountCodeAsDraft()
  }

  prepareDataTallyTracking() {
    const {
      booking,
      currentStep,
      timeType,
      serviceTypes,
      locations,
      selectedVehicleTypeID,
      selectedServiceTypeID
    } = this.props

    const defaultService = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)?.[0] || {}
    const vehicleType = _.find(defaultService?.vehicle_types, { id: selectedVehicleTypeID })
    const tallyData = TallyUtils.calculateTallyData({
      locations,
      vehicleTypeID: selectedVehicleTypeID,
      timeType,
    }, { booking, specificStep: currentStep, vehicleType })

    const returnData = {
      price: tallyData.price > 0 ? tallyData.price : 0,
      distance: tallyData.distance > 0 ? tallyData.distance : 0,
      transitTime: tallyData.transitTime > 0 ? tallyData.transitTime : 0,
    }

    return returnData
  }

  checkToGoToStep2(withoutLH = false) {
    const {
      selectedServiceTypeID,
      selectedVehicleTypeID,
      checkLocations,
      timeType,
      stepActions,
      assignDriverActions,
      currentCustomer,
      locations,
      documentReturn,
      documentReturnActions,
      checkLocationActions,
      locationActions,
      extraInfos,
      pickupTime,
    } = this.props
    const trackingData = this.prepareDataTallyTracking()
    const obj = {
     ...documentReturn,
     is_required: false
    }
    documentReturnActions.updateDocumentReturn(obj)

    if (withoutLH || _.isEmpty(checkLocations)) {
      const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
      LocationAPI.getCheckLocations(
        locations,
        { timeType, serviceTypeID: selectedServiceTypeID, vehicleTypeID: selectedVehicleTypeID, pickupTime },
        {
          countryCode: currentCustomer.country_code || extraInfos?.country_code,
          companyId: currentCustomer.current_company_id,
          isEnableGoogleMap
        },
        null,
        (resultApi) => {
          if (resultApi.status === 400) {
            stepActions.loaded()
            toastr.error(resultApi.error)
          } else {
            Promise.resolve(
              checkLocationActions.updateCheckLocationInfos(resultApi)
            ).then(() => {
              const { documentReturn: cloneObj } = this.props
              locationActions.updateCODPODFees(cloneObj, resultApi)
            })
            assignDriverActions.updateEstimatedWorkingTime(resultApi.estimated_working_time)
            this.handleResultApi(resultApi)
          }
        }
      )
    } else {
      this.handleResultApi()
    }
    CommonUtils.moengageTrackEvent('Step 1 to Step 2 Success', {
      'Total Fees': trackingData.price,
      'Distance': trackingData.distance,
      'Duration': Math.floor(trackingData.transitTime/60),
    })
    this.handleAppsFlyerTrackingEvent(BOOKING1_NEXT_PRESS)
  }

  filterEmptyLocations() {
    const { locations, locationActions } = this.props
    const notEmptyLocations = locationUtils.filterEmptyLocations(locations)
    locationActions.setLocations(notEmptyLocations)
  }

  checkEnableReturnDiscount(isLongHaul) {
    const {
      locations,
      roundTripDiscountActions,
      timeType,
      currentVehicleType,
      currentCustomer,
      roundTripDiscount,
      timeTypeUI,
      extraInfos,
    } = this.props
    const notEmptyLocations = locationUtils.filterEmptyLocations(locations)

    /* auto enable return discount when the last drop location equal to the pickup location */
    const appliedReturnDiscount = [NOW, QUICK_CHOICE, SCHEDULE]
    const pickup = notEmptyLocations[0]
    const destination = _.last(notEmptyLocations)
    const isPickupEqualLastDropOff = pickup.lat === destination.lat && pickup.lng === destination.lng
    const enableRoundTrip = _.isUndefined(currentVehicleType)
      ? []
      : currentVehicleType.settings.enable_round_trip
    const isEnableRoundTrip = roundTripDiscountUtils.shouldShow(
      locations,
      roundTripDiscount,
      timeTypeUI,
      currentCustomer.current_company_id,
      enableRoundTrip,
      setDefaultMaxLocation(currentCustomer, extraInfos),
      extraInfos,
    )
    if (
      notEmptyLocations.length > 2
      && !isLongHaul
      && appliedReturnDiscount.includes(timeType)
      && isPickupEqualLastDropOff
      && isEnableRoundTrip
    ) {
      roundTripDiscountActions.update(true)
    }
  }

  handleResultApi(result = null, isLongHaul = false) {
    const {
      popupCodActions, stepActions,
      outOfServiceStatusActions,
      checkLocations
    } = this.props
    const infos = result || checkLocations
    stepActions.loaded()
    stepActions.shouldGoToStep2(false)
    popupCodActions.popupCOD(infos)
    this.filterEmptyLocations()
    this.checkEnableReturnDiscount(isLongHaul)
    Promise.resolve(
      outOfServiceStatusActions.updateOutOfServiceStatus(infos)
    ).then(() => {
      toastr.remove()
      this.checkOutOfService()
    })
  }

  timeTypeAddressInvalid(invalidIndexes) {
    const {
      currentVehicleType,
      timeType,
      quickChoiceID,
      locations
    } = this.props

    // CASE: vehicle type not enable immediate booking
    // CASE: not select quick choice
    if ((currentVehicleType.settings.immediate_enabled === false && timeType === NOW)
      || timeType === QUICK_CHOICE
      || (timeType === NOW && currentVehicleType.quick_choices.length > 0 && quickChoiceID === 0)) {
      $('.PickupTime').addClass('error')
      return true
    }

    if (invalidIndexes.length > 0) {
      return true
    }

    if (timeType === FULL_DAY && locations[0].lat === undefined && locations[0].lng === undefined) {
      // Check locations for FULL_DAY - if lat & lng not valid then show input error.
      $('#booking_locations_attributes_1_name').parent().addClass('error')
    }
    return false
  }

  checkOutOfService() {
    const {
      outOfServiceStatus,
      stepActions, timeType,
      locationActions,
      timeTypeActions,
      timeTypeUIActions,
      navigate
    } = this.props

    // remove UI?
    $('.location-address').parent().removeClass('error')

    if (outOfServiceStatus.is_inside) {
      if (this.timeZoneIsValid()) {
        stepActions.toggleDraggableMarkers(false)
        if (outOfServiceStatus.out_of_service_area.is_surcharged === true) {
          $(`#${POPUP_ID_OUT_OF_SERVICE}`).addClass('visible')
        } else if (timeType === FULL_DAY && !_.isEmpty($(`#${POPUP_ID_FULL_DAY_S1}`))) {
          $(`#${POPUP_ID_FULL_DAY_S1}`).addClass('visible')
        } else {
          Promise.resolve(
            stepActions.nextStep(navigate)
          ).then(() => {
            const config = {
              element: $('.BookingWizard .Content'),
              positionScroll: 0,
              animation: 400,
              elementFocus: null
            }
            Utils.scrollTop(config)
          })
        }
      }
    } else if (timeType === LONG_HAUL) {
      $(`#${POPUP_ID_OUT_OF_SERVICE}`).removeClass('visible')
      if (outOfServiceStatus.long_haul_pickup.is_valid) {
        $(`#${POPUP_ID_OUT_OF_SERVICE}`).addClass('visible')
        if (outOfServiceStatus.change_longhaul_to_schedule === true) {
          $(`#${POPUP_ID_OUT_OF_SERVICE}`).addClass('visible')
          timeTypeActions.actionChangeTimeType(SCHEDULE)
          timeTypeUIActions.changeTimeTypeUI(SCHEDULE)
        }
      } else {
        locationActions.updatePickupZones(outOfServiceStatus.long_haul_pickup.zones)
        $(`#${POPUP_ID_LONG_HAUL_PICKUP}`).addClass('visible')
      }
    } else {
      $(`#${POPUP_ID_OUT_OF_SERVICE}`).addClass('visible')
    }
  }

  checkInitTmpLocationsDocumentReturn() {
    const { locationActions, timeType, tmpLocations } = this.props
    if (timeType === LONG_HAUL && !_.size(tmpLocations)) {
      Promise.resolve(
        locationActions.initTmpLocations()
      ).then(() => {
        this.checkToGoToStep3()
      })
    } else {
      this.checkToGoToStep3()
    }
  }

  checkToGoToStep3() {
    const {
      assignedPickupTime,
      pickupTime,
      pickupTimeActions,
      stepActions,
      locations,
      checkLocations,
      bookAgainDetails,
      documentReturn,
      documentReturnActions,
      locationActions,
      navigate,
    } = this.props

    if (this.verifyShoppingList() || !(this.verifyLocationsCPod()) || !this.verifyCustomReimbursement()) {
      return true
    }

    if (this.documentReturnAddressInvalid()) {
      const obj = {
        ...documentReturn,
        is_required: true, is_show: true
      }
      Promise.resolve(
        locationActions.initTmpLocations()
      ).then(() => {
        documentReturnActions.updateDocumentReturn(obj)
        const config = {
          element: $('.BookingWizard .Content'),
          positionScroll: 0,
          animation: 400,
          elementFocus: null
        }
        Utils.scrollTop(config)
      })
      return true
    }

    if (!_.isEmpty(assignedPickupTime) && pickupTime !== assignedPickupTime) {
      pickupTimeActions.changePickupTime(assignedPickupTime)
    }

    // Should verify CPOD when process next
    // eslint-disable-next-line consistent-return
    let verifyCPOD = false
    const cloneObjDocument = CPODUtils.cloneDocumentReturn({
      recipient_name: documentReturn.recipient_name,
      recipient_phone: documentReturn.recipient_phone,
      address_1: documentReturn.address_1,
      city: documentReturn.city,
      state: documentReturn.state,
      postal_code: documentReturn.postal_code,
      latitude: documentReturn.latitude,
      longitude: documentReturn.longitude,
      address_components: documentReturn.address_components
    })

    const invalidKeys = CPODUtils.invalidDocumentReturnAddress(cloneObjDocument, CPODUtils.keys())
    const validLocationsPOD = !!_.find(locations, ['need_pod', true])
    const isShowCPOD = CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails })

    if (_.size(invalidKeys) && validLocationsPOD && isShowCPOD) {
      const obj = {
        ...documentReturn,
        is_required: true, is_show: true
      }
      Promise.resolve(
        locationActions.initTmpLocations()
      ).then(() => {
        documentReturnActions.updateDocumentReturn(obj)
        const config = {
          element: $('.BookingWizard .Content'),
          positionScroll: 0,
          animation: 400,
          elementFocus: null
        }
        Utils.scrollTop(config)
      })
      verifyCPOD = true
    }
    if (verifyCPOD) {
      return true
    }

    Promise.resolve(
      stepActions.nextStep(navigate)
    ).then(() => {
      const config = {
        element: $('.BookingWizard .Content'),
        positionScroll: 0,
        animation: 400,
        elementFocus: null
      }
      Utils.scrollTop(config)
      const trackingData = this.prepareDataTallyTracking()
      CommonUtils.moengageTrackEvent('Step 2 to Step 3 Success', {
        'Total Fees': trackingData.price,
        'Distance': trackingData.distance,
        'Duration': Math.floor(trackingData.transitTime/60),
      })
      this.handleAppsFlyerTrackingEvent(BOOKING2_SERVICE_PRESS);
    })

    // clear error highlight
    $('.LocationContacts').find('.Input').each(function checlLocationContacts() {
      $(this).removeClass('error')
    })

    return false
  }

  documentReturnAddressInvalid() {
    const {
      timeType,
      locations,
      documentReturn,
      checkLocations,
      bookAgainDetails
    } = this.props
    let invalid = false
    const isAvailableBookingTracking = bookAgainDetails && bookAgainDetails.booking_tracking
    const bookingTracking = isAvailableBookingTracking ? bookAgainDetails.booking_tracking : documentReturn

    if (timeType === LONG_HAUL && !_.isUndefined(_.find(locations, { need_pod: true }))) {
      const requireFields = checkLocations && checkLocations.cod_pod === NEW_GEN_POD ? ['recipient_name', 'address_1', 'city', 'state', 'postal_code'] : []
      invalid = _.findIndex(requireFields, field => !bookingTracking[field]) !== -1
    }

    return invalid
  }

  closeCebRequestChangesPopup(confirm) {
    const { stepActions, navigate } = this.props
    if (confirm) {
      stepActions.nextStep(navigate)
    }
    ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup'))
  }

  handleActionInvalidPayment = () => {
    const { bookingActions, booking } = this.props
    const { bankTransfer } = booking
    bookingActions.handleInvalidPaymentAction(
      booking.country_code,
      bankTransfer?.id,
      () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup'))
    )
  }

  handleCheckIsValidPayment = async (callback = () => undefined) => {
    const {
      booking, popupActions, stepActions
    } = this.props
    const ceb = UtilsCommon.isCustomerEditBooking()
    const { bankTransfer } = booking
    let bankId = bankTransfer?.id || ''
    if (ceb) bankId = booking?.bookingPaymentDetails?.payment_method_setting_uuid || ''

    if (isPaymentBooking(booking?.payment_method_for_non_bp)) {
      const { settings } = bankTransfer
      const minimumAmountValue = settings?.minimumAmountValue
      const allowChargeFee = settings?.allowChargeFee
      const serviceFeeType = settings?.serviceFeeType
      const serviceFeeValue = settings?.serviceFeeValue
      const minChargeValueForPercentType = settings?.minChargeValueForPercentType
      const maxChargeValueForPercentType = settings?.maxChargeValueForPercentType
      const params = {
        minimumAmountValue,
        allowChargeFee,
        serviceFeeType,
        serviceFeeValue,
        minChargeValueForPercentType,
        maxChargeValueForPercentType,
        ceb: Boolean(ceb)
      }
      const response = await PaymentIntegrationAPI.checkIsValidPaymentAPI(bankId, ceb ? { ceb: Boolean(ceb) } : params)

      if (response.status === 200) {
        const { data } = response
        const { isValidStatus, isValidPricing } = data
        if (!UtilsCommon.isCustomerEditBooking()) {
          if (!isValidStatus) {
            popupActions.updateCurrentPopupID(INVALID_PAYMENT_POPUP)
          } else if (!isValidPricing) {
            stepActions.showErrorPopup(ICON_QUOTE_ID, I18n.t('errors.messages.quote_expired'), {
              specialClass: 'mt10 flex-center',
              list: [
                {
                  title: I18n.t('webapp.action.ok'),
                  specialClass: 'Button white green-text flex-index',
                  specialClick: () => this.handleActionInvalidPayment()
                }
              ]
            })
          } else {
            callback()
          }
        } else {
          if (booking?.payment_changed_price_status === PRICING_PAYMENT_INCREASE && (!isValidPricing || !isValidStatus)) {
            popupActions.updateCurrentPopupID(INVALID_PAYMENT_POPUP)
          } else callback()
        }
      }
    } else callback()
  }

  handleAppsFlyerTrackingEvent(eventName) {
    const {
      locations,
      currentCustomer,
      booking,
      extraInfos,
      timeType,
      currentServiceType,
      currentVehicleType,
    } = this.props

    const appsFlyerEventObj = generateAppsFlyerEventObj({
      extraInfos,
      currentServiceType,
      timeType,
      currentCustomer,
      booking,
      locations,
      currentVehicleType
    })
    appsFlyerTrackEvent(eventName, appsFlyerEventObj)
  }

  checkToSubmit() {
    // clear error highlight
    $('.LocationContacts').find('.Input').each(() => {
      $(this).removeClass('error')
    })
    const {
      locations,
      bookAgainDetails,
      currentCustomer,
      booking,
      popupActions,
      stepActions,
      navigate,
      accountManageActionsProps,
    } = this.props
    const invalidLocation = _.find(locations, location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng)
      && (_.isEmpty(location.recipient_name) || _.isEmpty(location.recipient_phone)))
    this.checkLongHaulPodUnselected()
    const showCebRequestChangesPopup = !_.isEmpty(bookAgainDetails) && !_.isEmpty(booking) && booking.is_major
      && UtilsCommon.isCustomerEditBooking()

    if (this.verifyShoppingActions()) {
      this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS_FAILED)
      return true
    }

    if (_.isEmpty(invalidLocation)) {
      if (!currentCustomer.id || currentCustomer.id === 0) {
        accountManageActionsProps.updateModalAccountManage(SIGN_UP_MODAL)
        this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS_BEFORE_SIGNED_IN)
        this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS_FAILED)
      } else if (!currentCustomer.verify_sms_code) {
        toastr.error(I18n.t('devise.sms_activations.unconfirmed_sms'))
        this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS_FAILED)
      } else {
        this.handleCheckIsValidPayment(() => {
          if (showCebRequestChangesPopup) popupActions.updateCurrentPopupID(POPUP_SUBMIT_EDITS)
          else stepActions.nextStep(navigate)
        })
      }
    } else {
      let hasFocus = false
      locations.forEach((loc) => {
        if (_.isEmpty(loc.recipient_name)) {
          $(`#${ID_RECIPIENT_NAME}${loc.id}`).closest('.Input').addClass('error')
          if (hasFocus === false) {
            $(`#${ID_RECIPIENT_NAME}${loc.id}`).focus()
          }
          hasFocus = true
          toastr.remove()
        }
        if (_.isEmpty(loc.recipient_phone)) {
          $(`#${ID_RECIPIENT_PHONE}${loc.id}`).closest('.Input').addClass('error')
          if (hasFocus === false) {
            $(`#${ID_RECIPIENT_PHONE}${loc.id}`).focus()
          }
          hasFocus = true
          toastr.remove()
        }
      })
      this.handleAppsFlyerTrackingEvent(BOOKING3_CONFIRM_PRESS_FAILED)
    }

    return false
  }

  cebRequestChangesPopup() {
    const info = {
      icon: ICON_CEB_REQUEST_CHANGES,
      title: I18n.t('webapp.new_booking.popup_ceb_request_changes_title'),
      specialClass: 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
    }
    const content = {
      title: I18n.t('webapp.new_booking.popup_ceb_update_request_changes_content'),
      specialClass: 'default-font reset m center',
    }
    const buttons = { specialClass: 'mt10 flex' }
    buttons.list = [
      {
        title: I18n.t('webapp.new_booking.back'),
        specialClass: 'Button gray green-text flex-index mr5',
        specialClick: () => this.closeCebRequestChangesPopup(false),
      },
      {
        title: I18n.t('webapp.new_booking.request'),
        specialClass: 'Button white green-text flex-index ml5 bold',
        specialClick: () => this.closeCebRequestChangesPopup(true),
      }
    ]
    return DynamicPopupWithButton(
      document.getElementById('CommonPopup'),
      info,
      content,
      buttons
    )
  }

  checkLongHaulPodUnselected() {
    const { documentReturnActions, locations } = this.props
    if (!_.find(locations, ['need_pod', true])) {
      const documentReturn = {
        recipient_name: '',
        recipient_phone: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        notes: '',
      }
      documentReturnActions.updateDocumentReturn(documentReturn)
    }
  }

  timeZoneIsValid() {
    const {
      extraInfos
    } = this.props

    if (extraInfos.check_time_zone === true && moment().format('ZZ') !== extraInfos.time_zone) {
      toastr.error(I18n.t('errors.booking.wrong_time_zone'))

      return false
    }

    return true
  }

  render() {
    const {
      currentStep, popupId, isWaitingSetData, isWaitingCalculate,
    } = this.props
    const isWaiting = isWaitingCalculate && (currentStep === 3 || isWaitingSetData)
    const confirmButtonContent = UtilsCommon.isCustomerEditBooking()
      ? I18n.t('webapp.new_booking.ceb_confirm') : I18n.t('webapp.new_booking.confirm_booking')
    return (
      <>
        <div className={`Actions ${isWaiting ? 'none-event' : ''}`}>
          {currentStep > 1 && (
            <button
              type="button"
              className="gray Button larger-font bold flex-two"
              onClick={() => this.prevStep()}
            >
              {I18n.t('webapp.new_booking.back')}
            </button>
          )}
          <button
            type="button"
            className={`green Button larger-font bold next-step2 ${currentStep > 1 ? 'flex-five' : ''}`}
            onClick={() => this.stepChecking()}
          >
            {currentStep < 3
              ? I18n.t('webapp.new_booking.next')
              : confirmButtonContent
            }
          </button>
        </div>
        {popupId === POPUP_SUBMIT_EDITS && <SubmitEditsModal />}
        {popupId === INVALID_PAYMENT_POPUP && <InvalidPaymentPopup />}
      </>
    )
  }
}

StepButton.propTypes = {
  serviceTypes: PropTypes.instanceOf(Array).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  locations: PropTypes.instanceOf(Array).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  outOfServiceStatus: PropTypes.shape({}).isRequired,
  documentReturn: PropTypes.shape({}).isRequired,
  tmpLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentStep: PropTypes.number.isRequired,
  quickChoiceID: PropTypes.number.isRequired,
  selectedServiceTypeID: PropTypes.number.isRequired,
  selectedVehicleTypeID: PropTypes.number.isRequired,
  pickupTime: PropTypes.string.isRequired,
  locationActions: PropTypes.shape({
    updateLazyAddressLocation: PropTypes.func
  }).isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  documentReturnActions: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  customerActions: PropTypes.shape({}).isRequired,
  popupCodActions: PropTypes.shape({}).isRequired,
  checkLocationActions: PropTypes.shape({}).isRequired,
  assignDriverActions: PropTypes.shape({}).isRequired,
  outOfServiceStatusActions: PropTypes.shape({}).isRequired,
  popupActions: PropTypes.shape({}).isRequired,
  othersActions: PropTypes.shape({}).isRequired,
  currentVehicleType: PropTypes.shape({}),
  currentServiceType: PropTypes.shape({}),
  bookAgainDetails: PropTypes.shape({}),
  checkLocations: PropTypes.shape({}),
  assignedPickupTime: PropTypes.string,
  booking: PropTypes.shape({
    payment_method_for_non_bp: PropTypes.string,
    bookingPaymentDetails: PropTypes.shape({
      payment_method_setting_uuid: PropTypes.string
    }),
    bankTransfer: PropTypes.shape({
      id: PropTypes.string,
      settings: PropTypes.shape({
        minimumAmountValue: PropTypes.number,
        allowChargeFee: PropTypes.bool,
        serviceFeeType: PropTypes.string,
        serviceFeeValue: PropTypes.number,
        minChargeValueForPercentType: PropTypes.number,
        maxChargeValueForPercentType: PropTypes.number
      })
    }),
  }),
  myShopping: PropTypes.shape({}),
  isShopping: PropTypes.bool,
  timeType: PropTypes.string,
  timeTypeUI: PropTypes.string,
  roundTripDiscountActions: PropTypes.shape({}).isRequired,
  roundTripDiscount: PropTypes.bool,
  onRef: PropTypes.func,
  dataChange: PropTypes.bool,
  discountCodeActions: PropTypes.shape({}).isRequired,
  calendarBookingActions: PropTypes.shape({}).isRequired,
  submitPartial: PropTypes.func,
  fullLoadView: PropTypes.bool,
  bookingActions: PropTypes.shape({}).isRequired,
  extraServices: PropTypes.shape({}).isRequired,
  outsideList: PropTypes.instanceOf(Array).isRequired,
  errorDistanceList: PropTypes.instanceOf(Array).isRequired,
  isErrorPickupLocation: PropTypes.bool.isRequired,
  handleShowChangePickupLocation: PropTypes.func.isRequired,
  setIsShowChangeTimeType: PropTypes.func.isRequired,
  extendedList: PropTypes.instanceOf(Array).isRequired,
  setShowComingSoonService: PropTypes.func,
  setIconComingSoonService: PropTypes.func,
  popupId: PropTypes.string,
  isWaitingSetData: PropTypes.bool,
  others: PropTypes.shape({}),
  assignedDriver: PropTypes.shape({}),
  isWaitingCalculate: PropTypes.bool,
}

StepButton.defaultProps = {
  currentVehicleType: undefined,
  currentServiceType: undefined,
  bookAgainDetails: undefined,
  checkLocations: undefined,
  assignedPickupTime: undefined,
  booking: undefined,
  myShopping: undefined,
  isShopping: false,
  timeType: undefined,
  timeTypeUI: undefined,
  roundTripDiscount: false,
  onRef: () => undefined,
  dataChange: false,
  fullLoadView: false,
  submitPartial: () => undefined,
  setIconComingSoonService: () => undefined,
  popupId: '',
  isWaitingSetData: false,
  others: {},
  assignedDriver: {},
  isWaitingCalculate: false,
  isGetSuccessDataEdit: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(StepButton))
